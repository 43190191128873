module.exports = {
  siteTitle: 'Rocket Zose', // <title>
  manifestName: 'Rocket Zose',
  manifestShortName: 'Rocketzose', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-overflow/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Rocket Zöse',
  heading: 'Photography, Art & Design Studio',
  // social
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/rocketzose',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:rocketzose@gmail.com',
    },
  ],
};
