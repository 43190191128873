import React from 'react';

function Instagallery({urls}){
    const imagenes = urls.map((url, i)=>{
        //limit max gallery images
        if(i>=24) return
        //build media url
        const postUrl = url
        if (url.substr(url.length - 1)!='/') url+='/'
        url+='media/?size=m'
        return(
                <a href={postUrl}>
                    <img src={url} alt='prueba' key='imagen_${i}'/>
                </a>
        )}
    )
    return (
        <div className="galeria">{imagenes}</div>  
    )
}

export default Instagallery