import React from 'react';
import InstagramEmbed from 'react-instagram-embed'

function Instapost ({url}){
    return(
        <InstagramEmbed
            url={url}
            maxWidth={320}
            hideCaption={false}
            containerTagName='div'
            protocol=''
            injectScript={true}
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
        />
    )
}

export default Instapost