import React, { useEffect } from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, graphql } from 'gatsby';

import pic1 from '../assets/images/pic-b-01.jpg';
import pic2 from '../assets/images/pic-b-02.jpg';
import pic3 from '../assets/images/art_main.jpg';
import pic4 from '../assets/images/branding_main.jpg';
import pic5 from '../assets/images/advertising_main.jpg';
import pic6 from '../assets/images/cover_main.jpg';
import pic7 from '../assets/images/intervention_main.jpg';
import pic8 from '../assets/images/advertising_main.jpg';

import Scroll from '../components/Scroll';
import Gallery from '../components/Gallery';
import HiddenGallery from '../components/HiddenGallery';
import Instapost from '../components/Instapost';
import Instagallery from '../components/Instagallery';

const H_PHOTO = [
  {
    src: require('../assets/images/fulls/01.jpg'),
    thumbnail: require('../assets/images/thumbs/01.jpg'),
    caption: 'Ad infinitum',
  },
  {
    src: require('../assets/images/fulls/02.jpg'),
    thumbnail: require('../assets/images/thumbs/02.jpg'),
    caption: 'Dressed in Clarity',
  },
  {
    src: require('../assets/images/fulls/03.jpg'),
    thumbnail: require('../assets/images/thumbs/03.jpg'),
    caption: 'Raven',
  },
  {
    src: require('../assets/images/fulls/04.jpg'),
    thumbnail: require('../assets/images/thumbs/04.jpg'),
    caption: "I'll have a cup of Disneyland, please",
  },
];

const ROW1_IMAGES = [
  {
    src: require('../assets/images/fulls/01.jpg'),
    thumbnail: require('../assets/images/thumbs/01.jpg'),
    caption: 'Ad infinitum',
  },
  {
    src: require('../assets/images/fulls/02.jpg'),
    thumbnail: require('../assets/images/thumbs/02.jpg'),
    caption: 'Dressed in Clarity',
  },
  {
    src: require('../assets/images/fulls/03.jpg'),
    thumbnail: require('../assets/images/thumbs/03.jpg'),
    caption: 'Raven',
  },
  {
    src: require('../assets/images/fulls/04.jpg'),
    thumbnail: require('../assets/images/thumbs/04.jpg'),
    caption: "I'll have a cup of Disneyland, please",
  },
];

const ROW2_IMAGES = [
  {
    src: require('../assets/images/fulls/05.jpg'),
    thumbnail: require('../assets/images/thumbs/05.jpg'),
    caption: 'Cherish',
  },
  {
    src: require('../assets/images/fulls/06.jpg'),
    thumbnail: require('../assets/images/thumbs/06.jpg'),
    caption: 'Different.',
  },
  {
    src: require('../assets/images/fulls/07.jpg'),
    thumbnail: require('../assets/images/thumbs/07.jpg'),
    caption: 'History was made here',
  },
  {
    src: require('../assets/images/fulls/08.jpg'),
    thumbnail: require('../assets/images/thumbs/08.jpg'),
    caption: 'People come and go and walk away',
  },
];
const IndexPage = ({data}) => {
    useEffect(function init() {
      console.log(data)

    }, [])
return (
  <Layout>
    <Header />

    <section id="banner">
      <header>
        <h2>Rocket Zöse<br/>Photography, Art & Design Studio</h2>
      </header>
      <p>
        Trabajamos para que tu marca sea más visible y destaque sobre la competencia. 
        <br/>
        Producimos imagenes artísticas personalizadas y estratégicas para tu empresa.
      </p>
      <footer>
        <Scroll type="id" element="contact">
          <a href="#contact" className="button style2 scrolly">
            VOTE POR ZÖSE
          </a>
        </Scroll>
      </footer>
    </section>

    <article className="container box style3">
        <header>
          <h2>Lo más nuevo</h2>
        </header>
        
        <Instapost url='https://www.instagram.com/p/CD0JQUAM_tH/'/>

        <Instagallery urls={ [
          'https://www.instagram.com/p/CDrqX1OsU6Y/',
          'https://www.instagram.com/p/CCpdLvDMDrJ/',
          'https://www.instagram.com/p/B-4x9kJjetj/'
          ] } />

        {/* <div
          dangerouslySetInnerHTML={{ __html: data.allFile.edges[0].node.childMarkdownRemark.html }}
        />   */}

    </article>

    <article id="first" className="container box style1 right">
    <HiddenGallery
      images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
        src,
        thumbnail,
        caption,
      }))}
    />
      <div className="inner">
        <header>
          <h2>
            Photographer
          </h2>
        </header>
        <p>
          Hacedor de imágenes.
        </p>
      </div>
    </article>

    {/* <article className="container box style2">
      <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div>
    </article> */}

    <article className="container box style1 left">
      <a href="/#" className="image fit">
        <img src={pic2} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
            Graphic Designer
          </h2>
          <p>
          Imaginista de realidades.
        </p>
        </header>
        </div>
    </article>

    {/* <article className="container box style2">
      <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div>
    </article> */}

    <article className="container box style1 right">
      <a href="/#" className="image fit">
        <img src={pic3} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
            Visual artist
          </h2>
        </header>
        <p>
          Ilusionista de colores.
        </p>
      </div>
    </article>

        
    {/* <article className="container box style2">
      <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div>
    </article> */}

    <article className="container box style1 left">
      <a href="/#" className="image fit">
        <img src={pic4} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
          Advertising campaigns
          </h2>
        </header>
        <p>
          Destacar sobre la competencia.
        </p>
      </div>
    </article>

        
    {/* <article className="container box style2">
      <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div>
    </article> */}

    <article className="container box style1 right">
      <a href="/#" className="image fit">
        <img src={pic5} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
            Logo / Branding / Marketing
          </h2>
        </header>
        <p>
          Imágenes artísticas personalizadas.
        </p>
      </div>
    </article> 
        
    {/* <article className="container box style2">
      <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div>
    </article> */}

    <article className="container box style1 left">
      <a href="/#" className="image fit">
        <img src={pic6} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
          Art Cover
          </h2>
        </header>
        <p>
          Music, Book & Magazines.
        </p>
      </div>
    </article>

    <article className="container box style1 right">
      <a href="/#" className="image fit">
        <img src={pic7} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
            Art Intervention
          </h2>
        </header>
        <p>
          Anything.
        </p>
      </div>
    </article>

    <article className="container box style1 left">
      <a href="/#" className="image fit">
        <img src={pic8} alt="" />
      </a>
      <div className="inner">
        <header>
          <h2>
          Marketing for Film
          </h2>
        </header>
        <p>
          MKT4FILM
        </p>
      </div>
    </article>

    <article className="container box style2">
      <header>
        <h2>VOTE POR ZÖSE</h2>
        <p>
          Estén al pendiente de mis promociones y giveaways en Instagram.
        </p>
      </header>

      {/* <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
        <Gallery
          images={ROW2_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div> */}

    </article>

    <article className="container box style3" id="contact">
      <header>
        <h2>LIVE, WORK, CREATE</h2>
        <p>Contacta ahora con el laboratorio creativo.</p>
      </header>
      <form method="post" action="#">
        <div className="row gtr-50">
          <div className="col-6 col-12-mobile">
            <input
              type="text"
              className="text"
              name="name"
              placeholder="Nombre"
            />
          </div>
          <div className="col-6 col-12-mobile">
            <input
              type="text"
              className="text"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="col-12">
            <textarea name="message" placeholder="Mensaje" />
          </div>
          <div className="col-12">
            <ul className="actions">
              <li>
                <input type="submit" value="Enviar Mensaje" />
              </li>
            </ul>
          </div>
        </div>
      </form>
    </article>
    
    { /*
    <article className="container box style3">
      <header>
        <h2>Elements</h2>
        <p>This starter has other elements.</p>
        <br />
        Check it out
        <Link to="/Element"> here </Link>
      </header>
    </article>
    */ }

    <Footer />
  </Layout>
);
        }
export default IndexPage;
